import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import questions from '../constants/questions';

export default function FAQ() {
  const [opened, setOpened] = useState(null);
  return <>
    <div className="container-mini">
      <h1>Questions fréquentes</h1>
      <div class="accordion">
        {questions.map((question, index) => (
          <div class={`accordion-item${opened === index ? ' open' : ''}`} key={index}>
            <button class="accordion-trigger" onClick={() => setOpened(opened === index ? null : index)}>{question.question()}{opened === index ? <FiChevronUp /> : <FiChevronDown />}</button>
            <div class="accordion-content">{question.answer()}</div>
          </div>
        ))}
      </div>
    </div>
  </>
}
