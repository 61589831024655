import React from 'react';
import { FiShare } from 'react-icons/fi';
import { DiAndroid, DiApple, DiWindows } from 'react-icons/di';
import { HiOutlineMenu } from 'react-icons/hi';
import Link from '../components/Link';
import { BsInfoCircle as IconInfo } from 'react-icons/bs';
import IconPdf from '../components/shapes/icon-pdf-2.svg';
import IconDownload from '../components/shapes/icon-pdf.svg';
import IconVideo from '../components/shapes/icon-videos.svg';
import IconAudio from '../components/shapes/icon-audios.svg';
import { TiPlus as IconPlus } from 'react-icons/ti';

export default [
  {
    question: () => 'L’application Pilou et Fleurette est-elle compatible avec mes appareils ?',
    answer: () => <>
      <p>La Webapp Pilou et fleurette est un site web traditionnel auquel on a ajouté des fonctions avancées. </p>
      <p>Vous pouvez le consulter sur tous les navigateurs récents, sur ordinateur, tablette ou smartphone.</p>
      <p>Nous vous conseillons d’utiliser Chrome sur Android et Safari sur iOS (iPhone, iPad)</p>
      <p>Sur les supports mobiles, vous pouvez télécharger une icône <Link to="/install">pour accéder directement à votre application depuis votre écran d’accueil</Link>.</p>
    </>,
  },
  {
    question: (intro = true) => (<>{intro ? <>Je ne vois pas de bouton « Installer l’application » sur mon navigateur.<br /></> : null}Comment puis-je installer Pilou et Fleurette sur mon appareil ?</>),
    answer: (intro = true) => <>
      {intro ? <p>Nous avons prévu un bouton pour installer simplement le raccourci. Toutefois, il est possible qu’il n’apparaisse plus si vous avez déjà installé puis supprimé le raccourci.</p> : null}
      <p>Vous pouvez l’installer en procédant de la manière suivante : </p>
      <p><u>Sur Android <DiAndroid /></u></p>
      <ul>
        <li>Ouvrez Chrome et allez sur le site <a href="https://pilou-et-fleurette-en-alpage.echoalp.com/">pilou-et-fleurette-en-alpage.echoalp.com</a></li>
        <li>Cliquez sur le bouton "Menu" <HiOutlineMenu /></li>
        <li>Cliquez ensuite sur "Ajouter à l'écran d'accueil"</li>
        <li>Terminez en cliquant sur le bouton "Ajouter" pour installer le raccourci sur votre appareil</li>
      </ul>
      <p><u>Sur iOS (iPhone et iPad) <DiApple /></u></p>
      <ul>
        <li>Ouvrez Safari et allez sur le site <a href="https://pilou-et-fleurette-en-alpage.echoalp.com/">pilou-et-fleurette-en-alpage.echoalp.com</a></li>
        <li>Cliquez sur le bouton "Partager" <FiShare /></li>
        <li>Faites défiler les icones du menu partage et cliquez sur le bouton "sur l'écran d'accueil"</li>
        <li>Puis sur le bouton "Ajouter" pour installer le raccourci sur votre appareil</li>
      </ul>
      <p><u>Sur ordinateur <DiWindows /></u></p>
      <p>Si vous le souhaitez, et que vous utilisez Google Chrome (PC et Mac) ou Edge sur PC, vous pouvez également installer l’application sur le bureau de votre ordinateur. Vous pourrez ainsi y accéder directement sans ouvrir votre navigateur internet.</p>
      <ul>
        <li>Allez sur le site <a href="https://pilou-et-fleurette-en-alpage.echoalp.com/">pilou-et-fleurette-en-alpage.echoalp.com</a></li>
        <li>Cliquez sur le Menu <HiOutlineMenu /></li>
        <li>Allez sur «Plus d’outils» et cliquez sur «Créer un raccourci...»</li>
        <li>Cochez la case «Ouvrir dans une fenêtre»</li>
      </ul>
    </>,
  },
  {
    question: () => 'J’ai installé l’application sur mon téléphone ou ma tablette, mais je ne la vois pas.',
    answer: () => <>
      <p>Il peut arriver que l’application ne soit pas affichée par défaut sur l’écran d’accueil du téléphone ou de la tablette. Sur certaines versions d’Android, elle sera installée dans les applications. Vous devez la mettre manuellement sur l’écran d’accueil.</p>
    </>,
  },
  {
    question: () => 'A quoi servent les boutons  à côté des fichiers pdf, audio et vidéos ?',
    answer: () => <>
      <p>En cliquant sur le bouton à gauche <IconPdf color="#000" width={20} height={20} /> ou <IconVideo color="#000" width={20} height={20} /> ou <IconAudio color="#000" width={20} height={20} /> ou sur le nom, vous ouvrez le fichier dans un onglet de votre navigateur.</p>
      <p>Il ne sera pas forcément disponible hors connexion.</p>
      <p>En cliquant sur le bouton <IconDownload color="#000" width={20} height={20} /> à droite, vous téléchargez le fichier sur votre appareil.</p>
      <p>Le téléchargement est utile si vous avez besoin de certains fichiers dans une zone sans connexion internet ou avec une connexion de mauvaise qualité. Dans les autres cas, préférez la lecture directe pour ne pas « encombrer » votre appareil.</p>
      <p>Le mode d'enregistrement du fichier dépend de votre appareil et de votre système système, par exemple :</p>
      <ul>
        <li>Sur Android, il suffit de cliquer sur le bouton télécharger pour que le fichier s'enregistre dans le dossier téléchargement.</li>
        <li>Sur iPHONE, le fichier va s'ouvrir puis vous devrez l'enregistrer manuellement, comme vous le faite habituellement sur votre navigateur.</li>
        <li>Sur iPAD, selon la version, vous devrez peut-être le faire DEPUIS SAFARI. Cela ne sera pas forcément possible depuis l'application.</li>
      </ul>
      <p>En cliquant sur le bouton <IconInfo color="#000" />, vous affichez la description du pdf correspondant.</p>
      <p>En cliquant sur le bouton <IconPlus color="#000" />, vous deployez la liste de sons contenu dans ce menu.</p>
    </>,
  },
  {
    question: () => 'Pourquoi je n\'arrive pas à lire les vidéos en streaming sur mon iPAD ?',
    answer: () => <>
      <p>Sur certaines versions, la lecture directe ne fonctionne  pas. Vous devez d'abord télécharger le fichier vidéo puis l'ouvrir depuis votre iPAD.</p>
    </>,
  },
  {
    question: () => 'Pourquoi les pdf ne s’affichent pas sur mon navigateur ?',
    answer: () => <>
      <p>Certains navigateurs traitent les pdf comme des fenêtres « pop-up ». Ils demandent au préalable d’autoriser l’affichage de ces fenêtres.</p>
      <p>Si rien ne se passe lorsque vous cliquez pour afficher un pdf, vérifiez si un message ou une notification n’attend pas votre validation.</p>
    </>,
  },
  {
    question: () => 'Où se trouvent les fichiers téléchargés ?',
    answer: () => <>
      <p>L’ensemble des fichiers pdf, audio et vidéos que vous téléchargez depuis Pilou et Fleurette se trouvent dans les dossiers habituels de votre appareil. Ils sont ainsi disponibles même si vous n’avez plus de connexion internet. Attention toutefois au poids de ces fichiers. Pensez à les supprimer lorsque vous n’en avez plus besoin.</p>
      <p>Sur Android, vous trouverez les fichiers téléchargés dans l’application « Mes fichiers » , « téléchargements »</p>
      <p>Sur iOS, vous pouvez les retrouver dans l’application « Fichiers », « Téléchargements »</p>
      <p>Pour pouvoir télécharger des fichiers, le navigateur web de votre tablette ou téléphone doit être autorisé à accéder à l’espace de stockage.</p>
      <p>En cas de doute, vous pouvez vérifier les autorisations dans les Paramètres :<br />Par exemple sur Android : Paramètres/Applications/Chrome > Autorisations sur Android</p>
    </>,
  },
  {
    question: () => 'Je n\'arrive pas à me connecter au site Pilou et Fleurette. Qui dois-je contacter ?',
    answer: () => <>
      <p>Au préalable, vérifiez que votre navigateur est récent et à jour.</p>
      <p>Sur tablette ou téléphone, utilisez de préférence Google Chrome ou Safari.</p>
      <p>Vous pouvez également vider le "cache" du navigateur.</p>
      <p>Si malgré tout, vous ne parvenez pas à vous connecter, contactez la Société d’Economie Alpestre de votre département ou canton.</p>
    </>,
  },
];
